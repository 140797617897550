import { DIMENSIONS } from '@dce-front/onewebapp-dive-utils';
import type { Ratio } from '@dce-front/onewebapp-utils';
import type { JSX } from 'react';
import { memo } from 'react';
import { isOngoing } from '../../helpers/card/card-helper';
import { useInvariantSelector } from '../../helpers/hooks/useInvariantSelector';
import {
  getHodorSticker,
  useGetDiveStickerProps,
} from '../../helpers/stickers/stickers-helper';
import {
  applicationResizeSelector,
  featUpcomingHodorStickerSelector,
} from '../../store/slices/application-selectors';
import type { ContentStrateV5 } from '../../templates/LandingV5/data/formatter';
import CardLogoChannel from '../CardContents/CardLogoChannel/CardLogoChannel';
import CoverImage from '../CoverImage/CoverImage';
import ProgressBar from '../ProgressBar/ProgressBar';
import styles from './CarrouselTemplate.css';
import { Sticker } from '@dce-front/dive';

export type CarrouselTemplateItemProps = {
  borderRadius?: boolean;
  content: ContentStrateV5;
  isDesktopOnly?: boolean;
  isMobileOnly?: boolean;
  ratio: Ratio;
};

function CarrouselTemplateItem({
  borderRadius = false,
  content,
  isDesktopOnly,
  isMobileOnly,
  ratio,
}: CarrouselTemplateItemProps): JSX.Element {
  const {
    altImage,
    altLogoChannel,
    endTime,
    isInOffer = true,
    startTime,
    title,
    URLImageOptimizedCompact,
    URLImageOptimizedRegular = '',
    URLLogoChannel = '',
    stickers,
  } = content;
  const onGoing = { startTime, endTime };
  const isLive = isOngoing(onGoing);
  const isProgressBarDisplayed = !!(
    startTime &&
    endTime &&
    isLive &&
    isInOffer
  );
  const isFeatUpcomingHodorSticker = useInvariantSelector(
    featUpcomingHodorStickerSelector,
  );
  const resizeMode = useInvariantSelector(applicationResizeSelector);

  const stickerProps = useGetDiveStickerProps({
    sticker: getHodorSticker({
      stickers,
      isFeatUpcomingHodorSticker,
    }),
    startTime,
    endTime,
    showLiveSticker: isLive && isInOffer,
  });

  return (
    <>
      <CoverImage
        dimensions={(DIMENSIONS as any).CARROUSEL[ratio]}
        borderRadius={borderRadius}
        image={{
          default: URLImageOptimizedRegular,
          mobile: URLImageOptimizedCompact,
        }}
        onClickData={content}
        title={altImage || title}
        isDesktopOnly={isDesktopOnly}
        isMobileOnly={isMobileOnly}
        resizeMode={resizeMode}
        isArtDirection
      >
        <>
          {!!stickerProps && (
            <Sticker
              {...stickerProps}
              className={styles.carrouselTemplate__sticker}
            />
          )}
          {URLLogoChannel ? (
            <CardLogoChannel
              key="logochannel"
              logo={{ default: URLLogoChannel }}
              altImage={altLogoChannel}
              isProgressBarDisplayed={isProgressBarDisplayed}
            />
          ) : null}
        </>
      </CoverImage>
      {isProgressBarDisplayed && (
        <div className={styles.carrouselTemplate__progressBarPositioner}>
          <ProgressBar startTime={startTime} endTime={endTime} />
        </div>
      )}
    </>
  );
}

export default memo(CarrouselTemplateItem);
