import { useVirtualizer } from '@tanstack/react-virtual';
import classNames from 'classnames/bind';
import { cloneElement, memo, useEffect, useRef, type JSX } from 'react';
import { OVERSCAN_HORIZONTAL_LIST } from '../../../constants/strates';
import { useOnFocusable } from '../../../helpers/hooks/useOnFocusable';
import type { MediaDimensions } from '../../../helpers/mediaDimensions/getMediaDimensions';
import { FocusManager } from '../../../helpers/oneNavigation/FocusManager';
import type { ContentRowVirtualProps } from '../ContentRowVirtual';
import styles from './HorizontalListVirtual.module.css';

const cx = classNames.bind(styles);

const NO_ITEMS_TO_FETCH = 10;

type HorizontalListVirtualProps = Pick<
  ContentRowVirtualProps,
  | 'ratio'
  | 'imageSize'
  | 'isOrderedList'
  | 'items'
  | 'getNextContent'
  | 'itemOffset'
  | 'isFromDetail'
> & {
  mediaDimensions: MediaDimensions;
  focusManager?: FocusManager;
};

function HorizontalListVirtual({
  ratio,
  imageSize,
  isOrderedList,
  items = [],
  getNextContent,
  itemOffset = 0,
  isFromDetail,
  mediaDimensions,
  focusManager,
}: HorizontalListVirtualProps): JSX.Element | null {
  const focusState = focusManager?.getFocusState();

  const initialElementIndex = Math.max(
    itemOffset,
    focusState?.elementIndex ?? 0,
  );

  const scrollRef = useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: items.length,
    horizontal: true,
    initialOffset: itemOffset * mediaDimensions.widthMediaCard,
    overscan: Math.max(initialElementIndex, OVERSCAN_HORIZONTAL_LIST),

    getScrollElement: () => scrollRef.current,
    estimateSize: () => mediaDimensions.widthMediaCard,
  });

  const virtualItems = virtualizer.getVirtualItems();

  useOnFocusable(
    focusManager,
    !!virtualItems.length &&
      (!focusState || focusState.elementIndex < items.length),
  );

  useEffect(() => {
    const lastItem = virtualItems[virtualItems.length - 1];
    if (lastItem && lastItem.index >= items.length - 1) {
      getNextContent?.(NO_ITEMS_TO_FETCH);
    }
  }, [virtualItems, items.length, getNextContent]);

  useEffect(() => {
    virtualizer.scrollToIndex(initialElementIndex);
  }, [initialElementIndex, virtualizer]);

  const ListComponent = isOrderedList ? 'ol' : 'ul';

  return (
    <div
      ref={scrollRef}
      className={cx('HorizontalListVirtual', 'HorizontalList', {
        'HorizontalListVirtual--detailV5': isFromDetail,
      })}
      style={{
        height: `${mediaDimensions.heightMediaCard + 20}px`,
      }}
    >
      <ListComponent
        className={cx('HorizontalListVirtual__list')}
        style={{
          width: `${virtualizer.getTotalSize()}px`,
        }}
        {...(ratio && imageSize && { 'data-ratio': `${ratio}${imageSize}` })}
      >
        {virtualItems.map((virtualItem) => {
          return cloneElement(items[virtualItem.index]!, {
            key: virtualItem.key,
            index: virtualItem.index,
            style: {
              position: 'absolute',
              width: `${virtualItem.size}px`,
              transform: `translateX(${virtualItem.start}px)`,
            },
          });
        })}
      </ListComponent>
    </div>
  );
}

export default memo(HorizontalListVirtual);
