import type { ImageSize } from '@dce-front/onewebapp-dive-utils';
import { animateScroll, canUseRaf, Ratio } from '@dce-front/onewebapp-utils';
import classNames from 'classnames';
import type { JSX, LegacyRef } from 'react';
import { PureComponent } from 'react';
import ContentRowHorizontalScroller from '../ContentRow/ContentRowHorizontalScroller';
import ContentRowNav from '../ContentRow/ContentRowNav/ContentRowNav';
import type { ContentRowLabels } from '../ContentRow/types';
import styles from './Carousel.module.css';

export type CarouselProps = {
  children: React.ReactElement[];
  labels: ContentRowLabels;
  ratio: Ratio;
  disableLazyLoad?: boolean;
  imageSize?: ImageSize;
  isCarrousel?: boolean;
  isCover?: boolean;
  isTvDevice?: boolean;
  mobileItems?: React.ReactElement[];
  mobileRatio?: Ratio;
  showControls?: boolean;
};

type CarouselState = {
  currSlide: number;
  displayNextNav: boolean;
  displayPrevNav: boolean;
};

/**
 * Component used in first strates on mobile (in landings)
 * and can be used in strate with template name 'CARROUSEL' (for all devices)
 */
export class Carousel extends PureComponent<CarouselProps, CarouselState> {
  constructor(props: CarouselProps) {
    super(props);

    this.state = {
      currSlide: 0,
      displayPrevNav: false,
      displayNextNav: props.children.length > 1,
    };
  }

  static defaultProps = {
    disableLazyLoad: false,
    imageSize: 'normal',
    isCarrousel: false,
    isCover: false,
    isTvDevice: false,
    mobileItems: [],
    mobileRatio: Ratio.Ratio169,
    showControls: true,
  };

  maxDots = 3;

  scrollerElement: HTMLElement | null = null;

  touchScrollLeft: number | null = null;

  bindNextButton: LegacyRef<HTMLButtonElement> | undefined;

  bindPrevButton: LegacyRef<HTMLButtonElement> | undefined;

  handleChangeSlide = (currSlide: number): void => {
    const nbSlides = this.props.children.length;

    if (currSlide >= nbSlides || currSlide < 0) {
      return;
    }

    this.setState({
      currSlide,
      displayPrevNav: currSlide > 0,
      displayNextNav: currSlide < nbSlides - 1,
    });
    const offset = (this.scrollerElement?.offsetWidth || 0) * currSlide;

    if (canUseRaf()) {
      window.requestAnimationFrame(() => {
        if (this.scrollerElement) {
          animateScroll(this.scrollerElement, offset, 200);
        }
      });

      if (!this.props.disableLazyLoad) {
        // Useful to trigger picture loading from lazyload:
        // Scroll from 1px down and up changing slide
        window.scroll(0, 1);
        window.scroll(0, -1);
      }
    } else if (this.scrollerElement) {
      this.scrollerElement.scrollLeft = offset;
    }
  };

  handleTouchStart = (event: React.TouchEvent): void => {
    this.touchScrollLeft = event.touches[0].clientX;
  };

  handleTouchMove = (event: React.TouchEvent): void => {
    if (!this.touchScrollLeft) {
      return;
    }

    const touchScrollRight = event.touches[0].clientX;
    const touchScrollDiff = this.touchScrollLeft - touchScrollRight;

    if (Math.abs(touchScrollDiff)) {
      if (touchScrollDiff > 0) {
        this.goNext();
      } else {
        this.goPrev();
      }
    }
    /* reset values */
    this.touchScrollLeft = null;
  };

  getActiveDot(): number {
    const { currSlide } = this.state;
    const nbSlides = this.props.children.length;
    let activeDot = 0;

    if (currSlide === 0) {
      activeDot = 0;
    } else if (currSlide === 1 || currSlide < nbSlides - 1) {
      activeDot = 1;
    } else {
      activeDot = 2;
    }

    return activeDot;
  }

  goNext = (): void => this.handleChangeSlide(this.state.currSlide + 1);

  goPrev = (): void => this.handleChangeSlide(this.state.currSlide - 1);

  renderDots(): JSX.Element[] {
    const nbSlides = this.props.children.length;
    const nbDots = Math.min(nbSlides, this.maxDots);
    const activeDot = this.getActiveDot();

    return [...Array(nbDots)]
      .map((e, i) => i)
      .map((dot, index) => (
        <li
          key={`dot-${dot}`}
          className={classNames(styles.carousel__dotsItem, {
            [styles['carousel__dotsItem--active']]: index === activeDot,
          })}
        >
          {index}
        </li>
      ));
  }

  bindScroller = (element: HTMLElement | null): void => {
    this.scrollerElement = element;
  };

  render(): JSX.Element {
    const {
      children,
      imageSize,
      isCarrousel,
      isCover,
      isTvDevice,
      labels,
      mobileItems,
      mobileRatio,
      ratio,
      showControls,
    } = this.props;

    const { displayPrevNav, displayNextNav } = this.state;

    return (
      <div className={classNames(styles.carousel)}>
        {!isTvDevice && (
          <div className={styles.carousel__mobile}>
            <button
              className={classNames(styles.carousel__prevButton, {
                [styles['carousel__prevButton--active']]: displayPrevNav,
              })}
              onClick={this.goPrev}
              ref={this.bindPrevButton}
              tabIndex={0}
              type="button"
            >
              <ContentRowNav type="prev" />
            </button>
            <div
              className={classNames({
                [styles['carousel--cover']]: isCover,
              })}
            >
              <ul
                data-ratio={`${mobileRatio}${imageSize}`}
                ref={this.bindScroller}
                className={styles.carousel__track}
                onTouchStart={this.handleTouchStart}
                onTouchMove={this.handleTouchMove}
              >
                {((mobileItems?.length && mobileItems) || children).map(
                  (item, index) => (
                    <li
                      className={styles.carousel__item}
                      key={`${item.key}-${String(index)}`}
                    >
                      {item}
                    </li>
                  ),
                )}
              </ul>
            </div>
            <button
              className={classNames(styles.carousel__nextButton, {
                [styles['carousel__nextButton--active']]: displayNextNav,
              })}
              onClick={this.goNext}
              ref={this.bindNextButton}
              tabIndex={0}
              type="button"
            >
              <ContentRowNav type="next" />
            </button>
            {children.length > 1 && (
              <ol className={styles.carousel__dots}>{this.renderDots()}</ol>
            )}
          </div>
        )}
        <div className={styles.carousel__tablet}>
          <ContentRowHorizontalScroller
            ratio={ratio}
            imageSize={imageSize}
            isCarrousel={isCarrousel}
            showControls={showControls}
            labels={labels}
          >
            {children.map((child, index) => (
              <li
                className={styles.carousel__item}
                key={`item-${child.key}-${String(index)}`}
              >
                {child}
              </li>
            ))}
          </ContentRowHorizontalScroller>
        </div>
      </div>
    );
  }
}
