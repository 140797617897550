import type { JSX } from 'react';
import { memo } from 'react';
import { getLiveContentProgressPercentage } from '../../helpers/liveTV/liveTV-helper';
import SimpleProgressBar from './SimpleProgressBar';

export type ProgressBarProps = {
  startTime?: number; // Milliseconds
  endTime?: number; // Milliseconds
  userProgress?: number;
  isCompleted?: boolean;
  isOnGoing?: boolean;
  isLiveShow?: boolean;
  shouldDisplayCheckIcon?: boolean;
};

function ProgressBar({
  startTime = 0,
  endTime = 0,
  userProgress,
  isCompleted = false,
  isOnGoing = false,
  isLiveShow = false,
  shouldDisplayCheckIcon = true,
}: ProgressBarProps): JSX.Element {
  const percentageProgress =
    userProgress || getLiveContentProgressPercentage(startTime, endTime) || 0;

  return (
    <SimpleProgressBar
      percentageProgress={percentageProgress}
      isCompleted={isCompleted}
      isOnGoing={isOnGoing}
      isLiveShow={isLiveShow}
      shouldDisplayCheckIcon={shouldDisplayCheckIcon}
    />
  );
}

export default memo(ProgressBar);
