import classNames from 'classnames';
import type { JSX } from 'react';
import IconCarouselArrow from '../../../assets/svg/carousselArrow.svg';
import styles from './ContentRowNav.module.css';

export type ContentRowNavProps = {
  type: 'prev' | 'next';
};

/** Render a particular button (previous or next) for `contentRow` template */
function ContentRowNav({ type }: ContentRowNavProps): JSX.Element {
  return (
    <div
      className={classNames(
        styles.contentRowNav,
        styles[`contentRowNav--${type}`],
      )}
    >
      <IconCarouselArrow className={styles.contentRowNav__icon} />
    </div>
  );
}

export default ContentRowNav;
