import classNames from 'classnames';
import type { JSX } from 'react';
import { memo } from 'react';
import IconCheck from '../../assets/svg/check.svg';
import { PROGRESS_BAR_COMPLETION_THRESHOLD } from '../../constants/limits';
import { useTranslation } from '../../lang';
import styles from './ProgressBar.css';

export type SimpleProgressBarProps = {
  percentageProgress: number | null;
  isCompleted?: boolean | null;
  isOnGoing?: boolean;
  isLiveShow?: boolean;
  shouldDisplayCheckIcon?: boolean;
};

function SimpleProgressBar({
  percentageProgress,
  isCompleted = false,
  isOnGoing = false,
  isLiveShow = false,
  shouldDisplayCheckIcon = true,
}: SimpleProgressBarProps): JSX.Element | null {
  const { t } = useTranslation();

  if (percentageProgress === null) {
    return null;
  }

  if (
    isOnGoing &&
    (isCompleted || percentageProgress > PROGRESS_BAR_COMPLETION_THRESHOLD) &&
    shouldDisplayCheckIcon
  ) {
    return (
      <div className={styles.ProgressBar__check} role="progressbar">
        <IconCheck
          className={styles.ProgressBar__icon}
          title={t('Icon.check')}
        />
      </div>
    );
  }

  return (
    <div
      className={styles.ProgressBar}
      role="progressbar"
      aria-label={`${percentageProgress}%`}
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuenow={percentageProgress}
    >
      <div
        className={classNames(styles.ProgressBar__progressLine, {
          [styles['ProgressBar__progressLine-onGoing']!]: isOnGoing,
          [styles['ProgressBar__progressLine-liveShow']!]:
            isOnGoing && isLiveShow,
        })}
        style={{ width: `${percentageProgress}%` }}
      />
    </div>
  );
}

export default memo(SimpleProgressBar);
