import { carrouselTv } from '@dce-front/onewebapp-core-css';
import type { ImageSize } from '@dce-front/onewebapp-dive-utils';
import type { Ratio } from '@dce-front/onewebapp-utils';
import classNames from 'classnames/bind';
import { memo, type JSX } from 'react';
import { useInvariantSelector } from '../../helpers/hooks/useInvariantSelector';
import {
  getMediaDimensions,
  transformMediaDimensionsToPx,
} from '../../helpers/mediaDimensions/getMediaDimensions';
import type { FocusManager } from '../../helpers/oneNavigation/FocusManager';
import { applicationResizeSelector } from '../../store/slices/application-selectors';
import HorizontalListVirtual from '../ContentRowVirtual/HorizontalListVirtual/HorizontalListVirtual';
import styles from './CarouselVirtual.module.css';

const cx = classNames.bind(styles);

export type CarouselVirtualProps = {
  ratio: Ratio;
  imageSize: ImageSize;
  items: JSX.Element[];
  focusManager?: FocusManager;
};

function CarouselVirtual({
  imageSize = 'normal',
  ratio,
  items = [],
  focusManager,
}: CarouselVirtualProps): JSX.Element | null {
  const resizeMode = useInvariantSelector(applicationResizeSelector);

  if (!items.length) {
    return null;
  }

  const mediaDimensions = transformMediaDimensionsToPx(
    getMediaDimensions({
      ratio,
      numberOfItemOnScreen: carrouselTv['169large'].wide,
      paddingLeftRight: 4.0625,
      paddingTopBottomItem: 0.25,
    }),
    resizeMode,
  );

  return (
    <div className={cx('CarouselVirtual')}>
      <div className={cx('CarouselVirtual__tablet')}>
        <HorizontalListVirtual
          ratio={ratio}
          imageSize={imageSize}
          items={items}
          mediaDimensions={mediaDimensions}
          focusManager={focusManager}
        />
      </div>
    </div>
  );
}
export default memo(CarouselVirtual);
