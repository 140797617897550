import type { DeviceToImageUrl } from '@dce-front/dive';
import type { ImageSize } from '@dce-front/onewebapp-dive-utils';
import { DIMENSIONS } from '@dce-front/onewebapp-dive-utils';
import classNames from 'classnames';
import type { JSX } from 'react';
import LogoChannel from '../../LogoChannel/LogoChannel';
import styles from './CardLogoChannel.css';

export type CardLogoChannelProps = {
  logo: DeviceToImageUrl;
  addShadow?: boolean;
  altImage?: string;
  borderRadius?: boolean;
  children?: JSX.Element | boolean;
  disabled?: boolean;
  isGradientActive?: boolean;
  isLiveTvCard?: boolean;
  isProgressBarDisplayed?: boolean;
  logoChannelSize?: ImageSize;
};

/**
 * Used to display a logo inside a card
 *
 * @param Children                return children component
 * @param logo                    object with default URL (required) and mobile URL (optional)
 * @param addShadow               add shadow around logo
 * @param borderRadius            add border radius
 * @param logoChannelSize         size of logoChannel (normal, large, or extraLarge)
 * @param altImage                alternative text for logo
 * @param isProgressBarDisplayed  information to display the logo higher is there is a progressBar on the card
 */
function CardLogoChannel({
  addShadow = false,
  altImage = '',
  borderRadius = false,
  children,
  disabled = false,
  isGradientActive = false,
  isLiveTvCard = false,
  isProgressBarDisplayed = false,
  logo,
  logoChannelSize = 'normal',
}: CardLogoChannelProps): JSX.Element {
  const isLargeLogo = logoChannelSize === 'large';
  const isExtraLargeLogo = logoChannelSize === 'extraLarge';
  const isLargeOverall = isLargeLogo || isExtraLargeLogo;

  return (
    <div
      className={classNames(styles.cardLogoChannel, {
        [styles['cardLogoChannel--shadow']!]: addShadow,
        [styles['cardLogoChannel--gradient']!]: isGradientActive,
        [styles['cardLogoChannel--gradient-livetvCard']!]:
          isGradientActive && isLiveTvCard,
        [styles['cardLogoChannel--borderRadius']!]: borderRadius,
        [styles['cardLogoChannel--disabled']!]: disabled,
      })}
    >
      {logo?.default && (
        <div
          className={classNames(styles.cardLogoChannel__channel, {
            [styles['cardLogoChannel__channel--largeLogo']!]:
              logoChannelSize === 'large',
            [styles['cardLogoChannel__channel--extraLargeLogo']!]:
              logoChannelSize === 'extraLarge',
            [styles['cardLogoChannel__channel--isProgressBarDisplayed']!]:
              isProgressBarDisplayed,
          })}
        >
          <LogoChannel
            image={logo}
            dimensions={(DIMENSIONS as any).LOGO_CHANNEL[logoChannelSize]}
            altImage={altImage}
          />
        </div>
      )}
      {children && (
        <div
          className={classNames(styles.cardLogoChannel__content, {
            [styles['cardLogoChannel__content--noLogo']!]: !logo?.default,
            [styles['cardLogoChannel__content--isLarge']!]: isLargeOverall,
            [styles['cardLogoChannel__content--isProgressBarDisplayed']!]:
              isProgressBarDisplayed,
          })}
        >
          {children}
        </div>
      )}
    </div>
  );
}

export default CardLogoChannel;
